import { RichText } from '@graphcommerce/graphcms-ui'
import { BlogContent } from '@graphcommerce/next-ui'
import renderers from '../GraphCMS/RichText/renderers'
import { ScriptRenderer } from '../GraphCMS/ScriptRenderer/ScriptRenderer'
import { RowBlogContentFragment } from './RowBlogContent.gql'

type RowBlogContentProps = RowBlogContentFragment

export function RowBlogContent(props: RowBlogContentProps) {
  const { content, customScript } = props
  if (!content) return null

  return (
    <BlogContent>
      <RichText {...content} sxRenderer={renderers} />
      {customScript?.script && <ScriptRenderer {...customScript} />}
    </BlogContent>
  )
}
