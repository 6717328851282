/* eslint-disable no-new */
/* eslint-disable new-cap */
import { Container } from '@mui/material'
import Script from 'next/script'
import { HTMLAttributes, useCallback, useEffect } from 'react'
import { RowFormsAppFragment } from './RowFormsApp.gql'

export function RowFormsApp(props: RowFormsAppFragment) {
  const { identity, referralUrl } = props

  const getConfig = useCallback(
    () => ({
      width: '100vw',
      height: '600px',
      ...(referralUrl ? { answers: { [referralUrl]: window.document.location.href } } : {}),
    }),
    [referralUrl],
  )

  const init = useCallback(() => {
    new globalThis.formsapp(identity, 'standard', getConfig())
  }, [identity, getConfig])

  useEffect(() => {
    if (globalThis.formsapp && !document.querySelector('iframe[src*="forms.app"]')) {
      init()
    }
  }, [init])

  const scriptProps = { formsappid: identity } as HTMLAttributes<HTMLDivElement>

  return (
    <Container
      maxWidth={false}
      sx={{
        '&.MuiContainer-root': {
          px: { xs: 0, md: 0 },

          '& iframe': {
            display: 'block',
          },
        },
      }}
    >
      <div {...scriptProps} />
      <Script src='https://forms.app/static/embed.js' strategy='afterInteractive' onLoad={init} />
    </Container>
  )
}
