import { extendableComponent } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { useRef } from 'react'
import { sanitizeHtml } from '../../utils/sanitizeHtml'
import { ScriptRendererFragment } from './ScriptRenderer.gql'

const name = 'ScriptRenderer' as const
const parts = ['root'] as const
const { classes } = extendableComponent(name, parts)

export function ScriptRenderer(props: ScriptRendererFragment) {
  const { script } = props
  const containerRef = useRef<HTMLDivElement>(null)

  const sanitizedScript = sanitizeHtml(script)

  if (!sanitizedScript) return null

  return (
    <Box
      id={classes.root}
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: sanitizedScript }}
      sx={(theme) => ({
        py: theme.spacings.md,
      })}
    />
  )
}
