import { RichText, RichTextProps } from '@graphcommerce/graphcms-ui'
import { VariantMessage } from '@graphcommerce/next-ui'
import { ScriptRenderer } from '../../ScriptRenderer/ScriptRenderer'
import type { RowColumnOneFragment } from '../RowColumnOne.gql'

type RowColumnOneProps = RowColumnOneFragment & {
  richTextOne?: Omit<RichTextProps, 'raw'>
}

export function Message(props: RowColumnOneProps) {
  const { colOne, richTextOne, id, customScript } = props

  return (
    <VariantMessage id={id}>
      <>
        <RichText {...colOne} {...richTextOne} />
        {customScript?.script && <ScriptRenderer {...customScript} />}
      </>
    </VariantMessage>
  )
}
